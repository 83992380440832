@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
